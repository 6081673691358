import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { storyblokEditable } from 'gatsby-source-storyblok';

import { useStoryblokState } from '../lib/storyblok';
import BlogPost from '../components/Blog/PageBlogPost';
import { get } from '../lib/nodash';
import deNodify from '../lib/deNodify';

const BlogPostTemplate = ({
  data: { storyblokEntry, relatedPosts, site },
  location,
}) => {
  const story = useStoryblokState(storyblokEntry);

  return (
    <div {...storyblokEditable(story.content)}>
      <BlogPost
        title={get('field_title_string', storyblokEntry)}
        story={story}
        siteUrl={site.siteMetadata.siteUrl}
        relatedPosts={deNodify(relatedPosts)}
        location={location}
      />
    </div>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default memo(BlogPostTemplate);

export const query = graphql`
  query StoryblokBlogPostContent($full_slug: String!, $category_glob: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      id
      name
      full_slug
      content
      field_title_string
      first_published_at
      internalId
    }
    relatedPosts: allStoryblokEntry(
      filter: {
        field_component: { eq: "BlogPost" }
        full_slug: { glob: $category_glob, ne: $full_slug }
        field_title_string: { ne: null }
      }
      sort: { fields: first_published_at, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          field_title_string
          first_published_at
          content
          full_slug
          internalId
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
